<template>
  <h1 class="title align-center pt-4">ニュース</h1>
  <div class="news">
    <a
      v-for="news in newsList"
      class="item"
      :href="toNewsDetail(news.id)"
      :key="news.id">
      <div class="date">{{news.date}}</div>
      <div class="title">{{news.title}}</div>
    </a>
    <a 
      href="/news"
      class="more">
      ニュース一覧を見る
    </a>
  </div>
</template>

<script>
export default {
  name: 'Plan',
  data() {
    return {
      newsList:[]
    }
  },
  created() {
    this.$api.get('https://cozhowm9fh.execute-api.ap-northeast-1.amazonaws.com/default/buybuy-news').then(res => {
      this.newsList = [...res.data.reverse()].map(n => {
        return {
          id: n.name.split('_')[0],
          date: n.name.split('_')[1],
          title: n.name.split('_')[2]
        }
      })
    })
  },
  methods: {
    toNewsList() {
      location.href = '/news'
    },
    toNewsDetail(id) {
      return `/news/${id}`
    }
  }
}
</script>

<style lang="scss">
.news {
  width: 65%;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 60px;  
  padding: 25px 10px;
  border: 1px solid #DFE4E9;
  border-radius: 10px;
  background: #F7F7F7;
  padding-bottom: 10px;
  .item, a {
    display: flex;
    font-size: 14px;
    padding: 5px 20px;
    cursor: pointer;
    text-decoration: none;
    .date {
      font-weight: bold;
      padding-right: 20px;
      color: black;
    }
    .title {
      color: #999999;
    }
  }
  .more {
    text-align: right;
    color: #F2B504;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 25px;
    text-decoration: none;
    display: block;
    &::after {
      content: "";      
      border-top: 6px solid #F2B504;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      position: relative;
      right: 15px;
      top: 13px;
      left: 5px;
    }
  }
}

@media screen and (max-width: 750px) {
  .news {
    width: 85%;
    .item {
      flex-direction: column;
      padding: 5px;
    }
  }
}
</style>
