<template>
  <div class="catch-phrase-box">
    <div class="catch-phrase-content">
      <h1>中国輸入をまるっとサポート</h1>
      <p class="description">
        <span>業界最安値で、商品選定から検品までフルサポート</span>
        <span>日本人スタッフが対応するので安心安全。</span>
      </p>
      <button 
        @click="toContact"
        class="btn-inquiry">
        詳しくはこちら
      </button>
    </div>
  </div>
  <div class="select-reasons">
    <div class="title">
      SellerPortを選ぶ理由
    </div>
    <div class="reasons fade-in-elements">
      <div v-for="reason in reasons" :key="reason.title" class="reason fade-in-element">
        <div class="reason-content">
          <div>
            <img :src="reason.icon" />
          </div>
          <div class="subtitle">{{reason.title}}</div>
          <div>{{reason.explain}}</div>
        </div>
      </div>
    </div>
  </div>
  <service-flows/>
  <div class="campaign">
    <div class="detail">
      <p class="mention">今すぐ加入すると、利用初月</p>
      <p class="phrase">
        会員費用0円&nbsp;&nbsp;&nbsp;&nbsp;代行手数料40%OFF
      </p>
      <p class="inquiry-sp">
        <button 
        @click="toContact"
        class="btn-inqury">お問い合わせ</button>
      </p>
    </div>
    <div class="icon">
      <p class="circle">今だけお得</p>
      <div class="border"></div>
    </div>
    <div class="inquiry-pc">
      <p>
        <button 
          @click="toContact"
          class="btn">お問い合せ</button>
      </p>
      <p>お気軽にお問合せください</p>
    </div>
  </div>
  <plan/>
  <div class="services">
    <h1 class="title">サービス一覧</h1>
    <div class="service-list fade-in-elements">
      <div class="service fade-in-element">
        <img src="@/assets/service1.png"/>
        <h2>仕入れ代行サービス</h2>
        <p>業界最安値で、商品選定から検品までフルでサポート日本人スタッフ対応するので安心安全。</p>
        <a href="/service">もっと見る</a>
      </div>
      <div class="service fade-in-element">
        <img src="@/assets/service2.png"/>
        <h2>検品サービス</h2>
        <p>初めての海外商品の輸入に不安ですか？スタッフがお届け前に商品を確認し、品質チェックを行うので安心です。</p>
        <a href="/service/inspection">もっと見る</a>
      </div>
      <div class="service fade-in-element">
        <img src="@/assets/service3.png"/>
        <h2>国際郵送サービス</h2>
        <p>買い付けた商品はSellerPort倉庫で大切に保管。物流加工後、ご希望の郵送方法でお届けします。</p>
        <a href="/service/logistics">もっと見る</a>
      </div>
      <div class="service fade-in-element">
        <img src="@/assets/service4.png"/>
        <h2>OEMサービス</h2>
        <p>工場とのやり取りは弊社の方でサポートさせていただきます。パッケージ制作など簡易なものから完全オリジナルまでサポートいたします。</p>
        <a href="/service/oem" class="inquiry">もっと見る</a>
      </div>                  
    </div>
  </div>
  <div class="faqs">
    <h1>よくあるご質問</h1>
    <faq
      v-for="(faq, index) in faqs"
      :key="index"
      :question="faq.question"
      :answer="faq.answer"
      />
  </div>
  <news/>
</template>

<script>
import Plan from '@/components/molecules/Plan.vue'
import Faq from '@/components/atoms/Faq.vue'
import News from '@/components/molecules/RecentNews.vue'
import ServiceFlows from '@/components/molecules/ServiceFlows.vue'

export default {
  name: 'Top',
  components: {
    Plan, Faq, News, ServiceFlows,
  },
  metaInfo: {
    title: 'About Us'
  },
  computed: {
    imageSrc() {
      return reason => require(reason.icon)
    }
  },
  data() {
    return {
      reasons: [
        {icon: 'img/reason-fee.svg', title: '手数料業界最安値', explain: '業界一番の安い手数料を保証'},
        {icon: 'img/reason-check.svg', title: 'スピーディ検品', explain: '日本基準でスピーディ検品対応、安全安心'},
        {icon: 'img/reason-oem.svg', title: 'OEMサポート', explain: '簡単OEMを通じて、売上向上貢献'},
        {icon: 'img/reason-support.svg', title: '商品選定サポート', explain: '海外ECサイトでの商品探しは時間がかかるもの。経験豊富なバイヤーが商品選定をサポート'},
        {icon: 'img/reason-staff.svg', title: '日本人スタッフ', explain: 'プロの日本人バイヤーが在籍し、丁寧な対応で安心'},                        
      ],
      faqs: [
        {
          question: 'お見積もりは無料でしょうか？',
          answer: '無料です、ぜひお問い合わせください。',
        },
        {
          question: '中国語は全く分かりませんが商品は希望のものが購入できるのでしょうか?',
          answer: '商品についてご不明な点などは担当のスタッフまでご相談ください。弊社よりショップに確認させて頂きます。またご注文シートでは行き違いの無いよう、可能な限り商品掲載URL・商品画像・サイト上での表現での種類・色等の詳細をご記載ください。',
        },
        {
          question: '価格交渉はしていますか？',
          answer: '仕入の際は、必ず交渉しております。また、値段交渉は購入時に販売先に掛け合うことは可能です。しかし、販売元によって、それぞれ注文量の条件等が異なりますので、その交渉が成功するかはお約束いたしかねます。弊社といたしましては、お客様のコスト削減につながるように努力いたします。',
        },
        {
          question: '購入後のキャンセルは可能ですか？',
          answer: 'ご入金前の場合はキャンセル可能です。 ※ご入金後の途中キャンセルはできませんので、ご了承ください。',
        },
        {
          question: '返品や交換は可能ですか？',
          answer: '返品や交換は、すぐに対応できればセラーに交渉することはできますが、国際発送の場合は日数が経過しているので基本的には返品や交換が難しいです。 返品の場合は、国内送料が別途かかる場合もございます。',
        },
      ]
    }
  },
  methods: {
    toContact() {
      location.href = '/contact'
    }
  }
}
</script>

<style lang="scss" scoped>
.catch-phrase-box {
  color: white;
  height: 252px;
  margin-top: -60px;
  .catch-phrase-content {
    background: rgba(51,51,51,0.78);
    height: 252px;
    h1, p {
      text-align: left;
    }
  }
}
.select-reasons {
  .title {
    padding: 50px 0px;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
  }
  .reasons {
    text-align: center;
    padding: 0 5%;
  }
  .reason {
    width: 33%;
    display: inline-flex;
    .reason-content {
      width: 80%;
      padding: 40px 20px;
      .subtitle {
        font-weight: bold;
        margin: 15px 0px;
      }
    }
  }
}
.campaign {
  position: relative;
  color: white;
  background: #F2B504;
  display: flex;
  .detail {
    flex: 5;
    text-indent: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    p {
      margin-bottom: 0;
      margin-top: 0;
    }
    .phrase {
      font-weight: bold;
      font-size: 2.4vw;
      text-indent: 5px;
    }
  }
  .icon {
    width: 200px;
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2B504;    
    .circle {
      color: #F2B504;
      text-align: center;
      font-weight: bold;
      background: white;
      z-index: 10;
      width: 150px;
      height: 150px;
      border-radius: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px; 
    }
    .border {
      z-index: 9;
      width: 156px;
      height: 156px;
      position: absolute;
      background: transparent;
      border: 1px solid;
      border-radius: 78px;      
    }
    &::after {
      content: "";
      display: flex;
      position: absolute;
      border-style: solid;
      border-width: 0 0 200px 200px;
      border-color: transparent transparent black transparent;
      z-index: 0;
    }       
  }
  .inquiry-pc {
    flex: 4;
    background: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding-top: 15px;
    p {
      margin-bottom: 10px;
      margin-top: 0;
    }
    .btn {
      padding: 5px 65px;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
h1 {
  margin: 50px;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
}
.faqs {
  background: #F7F7F7;
  padding-bottom: 80px;
  padding-top: 10px;  
}
.services {
  padding-bottom: 100px;
  .service-list {
    display: flex;
    width: 92%;
    margin: auto;
    justify-content: center;
    .service {
      flex: 1;
      text-align: center;
      margin: 10px 25px;
      background: white;
      border-radius: 10px;      
      box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center; 
      img {
        width: 100%;
        border-radius: 10px 10px 0 0;
      }
      h2, p {
        padding: 0 15px;
      }
      p {
        font-size: 12px;
      }
      h2 {
        font-size: 1.7vw;
      }
      a {
        text-decoration: none;
        background: #F2B504;
        border: none;
        color: white;
        padding: 8px 25px;
        cursor: pointer;
        margin-top: 15px;
        font-weight: bold;
      }
    }
  }
}

@media (min-width: 751px) {
  .catch-phrase-box {
    height: 670px;
    background: url('../assets/top-banner.jpg');
    background-size: cover;
    .catch-phrase-content {
      padding-top: 220px;
      padding-left: 60px;
      height: 450px;
      h1 {
        font-size: 48px;
        margin-bottom: 30px;
        font-weight: bold;
        margin: 0;
      }
      p {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 50px;
      }
      .description {
        span {
          display: block;
        }
      }
    }
  }
  .btn-inquiry {
    padding-left: 50px;
    padding-right: 50px;    
  }
  .campaign {
    .inquiry-sp {
      display: none;
    }
  }
  .services {
    .service {
      p {
        min-height: 75px;
        display: flex;
        align-items: center;
        margin-top: -10px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .catch-phrase-box {
    // margin-top: 60px;
    height: 312px;
    background: url('../assets/top-banner.jpg');
    background-size: cover;
    background-position: center;
    .catch-phrase-content {
      padding-top: 60px;
      h1 {
        font-size: 24px;
        margin: 20px;
        font-weight: bold;
        margin-top: 30px;
      }      
      p {
        padding-left: 20px;
        text-align: left;
      }
      img {
        width: 100%;
      }
      .description {
        padding-right: 20px;
      }
    }
    .btn-inquiry {
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  .select-reasons {
    .reasons {
      display: flex;
      flex-direction: column;
      .reason {
        width: 100%;
        justify-content: center;
      }
    }
  }

  .services {
    .service-list {
      flex-direction: column;
      .service:not(:nth-child(1)) {
        margin-top: 50px;
      }
      .service {
        h2 {
          font-size: 18px;
        }
      }
    }
  }
  .campaign {
    .detail {
      p {
        text-indent: 0;
        text-align: center;
        font-size: 12px;
      }
      .phrase {
        font-weight: bold;
        font-size: 16px;
      }
      .inquiry-sp {
        display: block;
        .btn-inqury {
          border: none;
          background: white;
          font-weight: bold;
          margin-top: 15px;
          font-size: 16px;
          border-radius: 2px;
          padding: 2px 10px;
        }
      }
    }
    .icon {
      width: 130px;
      height: 130px;
      .circle {
        width: 94px;
        height: 94px;
        font-size: 16px;
      }
      .border {
        width: 100px;
        height: 100px;
      }
      &::after {
        border-width: 0 0 130px 130px;
      }
    }
    .inquiry-pc {
      display: none;
    }
  }
}

</style>